body {
  background: #141414;
  color: #ffffff;
}

.movie-app > .row {
  overflow-x: auto;
  flex-wrap: nowrap;
}

.image-container {
  position: relative;
  transition: 0.7s;
}

.image-container:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  bottom: 0;
  background-color: black;
  width: 100%;
  opacity: 0;
  height: 60px;
  font-size: 20px;
  text-align: center;
  transition: 1s ease;
  box-sizing: border-box;
}

.image-container:hover .overlay {
  opacity: 0.9;
}


.overlay:hover .addRemove {
  font-size: large;
}

.overlay:active .addRemove{
  transform: scale(0.95);
}